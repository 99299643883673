import React from "react"
import { Helmet } from "react-helmet"

export default function Meta({ title }) {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`Délidoor Smart | ${title}`}</title>
            <link rel="canonical" href="https://delidoor.com.au" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#A2CA81" />
            <meta name="msapplication-TileColor" content="#A2CA81" />
            <meta name="theme-color" content="#ffffff"></meta>
            <meta name="facebook-domain-verification" content="4k8vw9at6p8qns85khv753fdnv367m" />
        </Helmet>
    )
}